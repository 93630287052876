<template>
  <div id="manage-payrolls">
    <h2 class="content-title" >Gestionar nóminas</h2>
    <div class="content-container" :class="loadingContent ? '' : 'visible'">
      <q-card class="content-card card">
        <p class="content-card__title">Estadísticas</p>
        <div class="payrolls-stats row">
          <div class="col-sm-8">
            <q-card>
              <q-card-section>
                <span class="payrolls-stats__title">Número de nóminas</span>
                <div class="payrolls-chart">
                  <LineChart :chart-data="numPayrolls" />
                </div>
              </q-card-section>
            </q-card>
          </div>
          <div class="col-sm-4">
            <q-card>
              <q-card-section>
                <span class="payrolls-stats__title">Total nóminas</span>
                <span class="payrolls-stats__count">{{ totalPayrolls }}</span>
              </q-card-section>
            </q-card>
            <q-card>
              <q-card-section>
                <span class="payrolls-stats__title">Nóminas enviadas</span>
                <span class="payrolls-stats__count">{{ totalSent }}</span>
              </q-card-section>
            </q-card>
            <q-card>
              <q-card-section>
                <span class="payrolls-stats__title">Nóminas pendientes</span>
                <span class="payrolls-stats__count">{{ totalPending }}</span>
              </q-card-section>
            </q-card>
          </div>
        </div>
      </q-card>
      <q-card class="content-card">
        <p class="content-card__title">Listado de nóminas</p>
        <div class="row content-card__filters">
            <q-input class="content-card__filter" v-model="filterStartDate" mask="##/####" label="Desde">
              <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy ref="monthPickerStart" transition-show="scale" transition-hide="scale">
                      <q-date 
                        v-model="filterStartDate"
                        minimal
                        mask="MM/YYYY"
                        emit-immediately
                        default-view="Years"
                        @input="checkDateStart"
                      >
                        <div class="row items-center justify-end">
                          <q-btn v-close-popup label="Cerrar" color="primary" flat />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                  </template>
              </q-input>
              <q-input class="content-card__filter" v-model="filterEndDate" mask="##/####" label="Hasta">
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy ref="monthPickerEnd" transition-show="scale" transition-hide="scale">
                      <q-date 
                        v-model="filterEndDate"
                        minimal
                        mask="MM/YYYY"
                        emit-immediately
                        default-view="Years"
                        @input="checkDateEnd"
                      >
                        <div class="row items-center justify-end">
                          <q-btn v-close-popup label="Cerrar" color="primary" flat />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                  </template>
              </q-input>
        </div>
        <div class="payrolls-container">
          <q-table
            no-data-label="No hay ninguna nómina para mostrar"
            :data="payrollsTD"
            :columns="payrollsTC"
            :filter="filter"
            :visible-columns="payrollsVC"
            row-key="payroll_id"
            selection="multiple"
            :selected.sync="selectedPayrolls"
          >

            <template v-slot:top>
              <q-btn 
                v-if="selectedPayrolls.length > 0" 
                class="table-btn" 
                color="primary" 
                label="Descargar"
                :loading="btnLoadingDownload" 
                @click="downloadPayroll" 
              />
              <q-btn 
                v-if="selectedPayrolls.length > 0" 
                class="table-btn" 
                color="red"
                label="Eliminar"
                :loading="btnLoadingDelete" 
                @click="deletePayroll" 
              />
              <q-space />
              <q-select 
                  class="form-filter col-sm-2" 
                  v-model="selectedSchool"
                  :options="allSchoolNames"
                  emit-value 
                  map-options 
                  label="Escuela"
              />
              <q-input borderless dense debounce="300" v-model="filter" placeholder="Buscar">
                <q-icon slot="append" name="search" />
              </q-input>
            </template>

            <template v-slot:body="props">
              <q-tr :props="props" :class="(props.row.status == 'uploaded') ? 'table__pending-row' : ''">
                <q-td>
                  <q-checkbox v-model="props.selected"/>
                </q-td>
                <q-td
                  v-for="col in props.cols"
                  :key="col.name"
                  :props="props"
                >
                  {{ col.value }}
                </q-td>
              </q-tr>
            </template>

          </q-table>
        </div>
      </q-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import dictionary from '../../mixins/dictionary'
import LineChart from "../../components/LineChart"
import moment from 'moment'

export default {
  mixins: [dictionary],

  components: {
		LineChart,
	},

  data() {
    return {
      loadingContent: true,
      btnLoadingDownload: false,
      btnLoadingDelete: false,
      filter: null,
      selectedPayrolls: [],
      selectedSchool: '',
      payrollsTC: [
        { name: 'payroll_id', label: 'ID de la nómina', field: 'payroll_id', align: 'center' },
        { name: 'firstname', label: 'Nombre', field: 'firstname', align: 'left', sortable: true },
        { name: 'lastname', label: 'Apellidos', field: 'lastname', align: 'left', sortable: true },
        { name: 'school_name', label: 'Escuela', field: 'school_name', align: 'left', sortable: true },
        { name: 'gross_salary', label: 'Bruto', field: 'gross_salary', align: 'center', sortable: true, type: 'number' },
        { name: 'net_salary', label: 'Neto', field: 'net_salary', align: 'center', sortable: true, type: 'number' },
        { name: 'date_start', label: 'Fecha inicio', field: 'date_start', align: 'center', sortable: true, sort: (a, b) =>  moment(a, "DD/MM/YYYY") - moment(b, "DD/MM/YYYY") },
        { name: 'date_end', label: 'Fecha fin', field: 'date_end', align: 'center', sortable: true, sort: (a, b) =>  moment(a, "DD/MM/YYYY") - moment(b, "DD/MM/YYYY")},
        { name: 'status', label: 'Estado', field: 'status', align: 'center', format: val => {
          return val == 'sent' ? 'Enviada' : 'Pendiente' 
        }, sortable: true},
        { name: 'payroll_type', label: 'Tipo', field: 'payroll_type', align: 'center', format: val => {
          return val == 'payroll' ? 'Nómina' : 'Paga extra' 
        }, sortable: true}
      ],
      payrollsVC: ['firstname', 'lastname', 'school_name', 'gross_salary', 'net_salary', 'date_start', 'date_end','status', 'payroll_type'],
      filterStartDate: '',
      filterEndDate: ''
    }
  },

  computed: {
    ...mapGetters('auth', [
      'userAuth'
    ]),

    ...mapGetters('payrolls', [
      'payrolls'
    ]),

    ...mapGetters('schools', [
      'schools'
    ]),

    payrollsTD() {
      let payrolls = this.payrolls
      let selectedSchool = this.selectedSchool
      if (selectedSchool > 0) {
        payrolls = payrolls.filter(payroll => payroll.school_id == selectedSchool)
      }

      if (this.filterStartDate !== '') {
        let completeDate = this.filterStartDate.split('/')
        let time = new Date(completeDate[1], completeDate[0]).getTime()
        payrolls = payrolls.filter(payroll => new Date(payroll.year_start, payroll.month_start).getTime() >= time)
      }

      if (this.filterEndDate !== '') {
        let completeDate = this.filterEndDate.split('/')
        let time = new Date(completeDate[1], completeDate[0]).getTime()
        payrolls = payrolls.filter(payroll => new Date(payroll.year_start, payroll.month_start).getTime() <= time)
      }

      return payrolls
    },

    allSchoolNames() {
      let allLabel = [{
        'label': 'Todas',
        'value': 0
      }]
      let schools = this.schools.map(school => {
        return {
          'label': school.name,
          'value': school.id
        }
      })
      return allLabel.concat(schools)
    },

    numPayrolls() {
      let env = this
      let count = this.schools.map(school => {
        let payrolls = env.payrollsTD.filter(payroll => payroll.school_id == school.id)
        let data = {}
        payrolls.map(payroll => {
          if (data[payroll.month_start + '-' + payroll.year_start] === undefined) {
              data[payroll.month_start + '-' + payroll.year_start] = 0
          }

          data[payroll.month_start + '-' + payroll.year_start]++
        })

        let finalDates = []

        for (let key in data) {
          finalDates.push({ label: key, value: data[key] })
        }

        finalDates = finalDates.sort((a,b) => {
          let aDate = a.label.split('-')
          let bDate = b.label.split('-')
          return new Date(aDate[1], aDate[0], 1) - new Date(bDate[1], bDate[0], 1)
        })

        return {
          name: school.name,
          data: finalDates
        }
      })
      return count
    },

    totalPayrolls() {
      return this.payrollsTD.length
    },

    totalSent() {
      return this.payrollsTD.filter(payroll => payroll.status == 'sent').length
    },

    totalPending() {
      return this.payrollsTD.filter(payroll => payroll.status == 'uploaded').length
    }
  },

  methods: {
    ...mapActions('payrolls', [
      'getPayrolls',
      'getPendingPayrolls'
    ]),

    emptySelection() {
      this.selectedPayrolls = []
    },

    async downloadPayroll() {
      let env = this
      env.btnLoadingDownload = true
      for (const payroll of env.selectedPayrolls) {
        let filename = payroll.dropbox_path.split('/').pop()
        let form = new FormData()
        form.append('action', 'download')
        form.append('token', this.userAuth)
        form.append('payrolls', JSON.stringify([payroll]))
        await axios
        .post('https://www.api.babyteca.es/api/payrolls', form, {responseType: 'blob'})
        .then(function (response) {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          env.notify('success', 'ok_download')
        }).catch(function () {
          env.notify('error', 'ko_download')
        })
      }
      env.btnLoadingDownload = false
      env.updateTable()
      env.emptySelection()
    },

    async deletePayroll() {
      this.btnLoadingDelete = true
      let form = new FormData()
      let env = this
      form.append('action', 'delete')
      form.append('token', this.userAuth)
      form.append('payrolls', JSON.stringify(this.selectedPayrolls))
      await axios
      .post('https://www.api.babyteca.es/api/payrolls', form)
      .then(function (response) {
        if (response.data.status == 'ok') {
          env.notify('success', 'ok_delete_payroll')
        } else {
          env.notify('error', 'ko_delete_payroll')
        }
        env.btnLoadingDelete = false
        env.updateTable()
        env.emptySelection()
      }).catch(function () {
        env.btnLoadingDelete = false
        env.notify('error', 'ko_delete_payroll')
      })
    },

    async updateTable() {
      let form = new FormData()
      form.append('token', this.userAuth)
      await this.getPayrolls(form)
      await this.getPendingPayrolls(form)
    },

    checkDateStart (val, reason) {
      if (reason === 'month') {
        this.$refs.monthPickerStart.hide()
      }
    },

    checkDateEnd (val, reason) {
      if (reason === 'month') {
        this.$refs.monthPickerEnd.hide()
      }
    }
  },

  async mounted() {
    let env = this
    env.loadingContent = true
    setTimeout(function(){
      env.loadingContent = false
    }, 100)
  }
}
</script>
