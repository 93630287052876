<template>
  <div class="chart">
    <line-chart 
      :data="chartData" 
      smooth 
      :legend="true" 
      :emphasis-label="false" 
      :label="false" 
      :tooltip="true" 
      :option="option" 
    />
  </div>
</template>

<script>
export default {
  name: "LineBase",
  props: ['chartData'],
  data() {
    return {
      option: {
        textStyle: {
          fontFamily: 'Quicksand'
        }
      }
    }
  }
}
</script>
